/**
 * @file navbar.css
 * @module navbar
 * @desc Contains CSS animations for the navbar component, including fade in and fade out animations.
 * 
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/CSS/@keyframes|MDN Keyframes Documentation}
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/CSS/animation|MDN Animation Documentation}
 * 
 * @author Chace Nielson
 * @created 2024-07-10
 * @updated 2024-07-19
 */

 @keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.fade-in {
  animation: fadeIn 0.3s forwards;
}

.fade-out {
  animation: fadeOut 0.3s forwards;
}

.shadow-inner-custom {
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.8);
  @apply shadow-primary
}