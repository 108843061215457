/**
 * @file VideoComponent.css
 * @module VideoComponentStyles
 * @desc CSS styles for the VideoComponent in React. This file defines the keyframes
 * for the blur animation and the animation class that is applied to the video element
 * in the VideoComponent.jsx file. The blur animation is designed to start after a delay 
 * of 0.2 seconds and runs for 0.8 seconds.
 * 
 * @see {@link ../components/VideoComponent.jsx|VideoComponent.jsx}
 * 
 * @author Chace Nielson
 * @created 2024-07-19
 * @updated 2024-07-19
 */

@keyframes blur {
  0% {
    filter: blur(0.5px);
  }
  100% {
    filter: blur(2px);
  }
}

.blur-animation {
  animation: blur 0.8s forwards 0.2s; /* 2 seconds animation duration */
}
