/* Base button style */
.button-class {
  @apply inline-flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2  ;
}

.button-base, .button-secondary {
  @apply button-class;

  position: relative;
  overflow: hidden;
}

.button-base::before, .button-secondary::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.5s ease;
  z-index: 0;
}

.button-base:hover::before, .button-secondary:hover::before {
  transform: scaleX(1);
  transform-origin: left;
}

.button-base:hover, .button-secondary:hover {
  color: white;
}

.button-content {
  position: relative;
  z-index: 5;
  @apply text-xs sm:text-base md:text-lg;
  filter: drop-shadow(0 1px 2px rgb(0, 0, 0));


}

/* Accent button variant */
.button-accent {
  @apply bg-accent focus:ring-accent border-secondary-dark border-opacity-50;
}

.button-accent::before {
  @apply bg-accent-dark
}

/* Secondary button variant */
.button-secondary {
  @apply bg-secondary focus:ring-secondary;
}

.button-secondary::before {
  @apply bg-secondary-dark
}

/* Primary button variant */
.button-primary {
  @apply bg-primary focus:ring-primary;
}

.button-primary::before {
  @apply bg-primary-dark
}
