/* ProfileCard.css */

.flip-card {
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

.flip-card-back {
  transform: rotateY(180deg);
}

/* Disable hover effect and enable click-to-flip on small screens */
@media (max-width: 640px) {
  .flip-card:hover .flip-card-inner {
    transform: none;
  }
  .flip-card-clickable {
    cursor: pointer;
  }
  .flip-card-clickable.flipped .flip-card-inner {
    transform: rotateY(180deg);
  }
}
