/**
 * @file index.css
 * @module index
 * @desc This CSS file contains the setup for Tailwind CSS and regular CSS styles for the entire document.
 * 
 * @see {@link https://tailwindcss.com/docs|Tailwind CSS Documentation}
 * 
 * @author Chace Nielson
 * @created 2024-07-10
 * @updated 2024-08-21
 */
@tailwind base;
@tailwind components;
@tailwind utilities;


@import url("https://fonts.googleapis.com/css2?family=Lexend");
@import url("https://fonts.googleapis.com/css2?family=Lexend&family=Open+Sans&family=Roboto&family=Lato&family=Montserrat&family=Nunito");
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&family=Bebas+Neue&display=swap');


/* fonts */
body {
  font-family: 'Poppins', sans-serif;
}

.titleFont {
  font-family: 'Bebas Neue', 'Impact', 'Arial', sans-serif;
  text-transform: uppercase; /* Ensures all text is uppercase */
}
 
/* general body */
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply bg-secondary;

}

/* for the home page setup the container for intro and game pannels */
.home-page-container {
  @apply relative w-full overflow-hidden;
}

/* heights of the intro and game pannels */
.intro-height{
  @apply h-128 md:h-144 lg:h-160 xl:h-176 2xl:h-208;
}
.pannel-height{
  @apply h-128 md:h-120 lg:h-128 xl:h-144 2xl:h-176;
}


/* background blur and opacity animation for being */
@keyframes fadeBlurIn {
  0% {
    backdrop-filter: blur(0);
  }
  100% {
    backdrop-filter: blur(10px); /* Adjust the blur value as needed */
  }
}

@layer utilities {
  .animate-blur {
    animation: fadeBlurIn 2s ease-in-out forwards;
  }
}

.faint-bg {
  @apply bg-secondary bg-opacity-60 animate-blur;
}

.text-shadow{
  filter: drop-shadow(0 0 2px rgb(0, 0, 0));
}

/* box that houses the video and text */
.home-page-box {
  @apply absolute inset-0 
  flex flex-col items-center justify-center 
  text-center 
  bg-black 
  bg-opacity-45

}

/* title and dscriptions + the line break for really ling text */
.home-page-title {
  @apply text-white text-4xl sm:text-5xl md:text-7xl lg:text-8xl 
  font-extrabold titleFont
  container px-4
  mb-1
  ;
}

.home-page-description {
  @apply text-white text-base sm:text-xl md:text-3xl lg:text-4xl 
  container px-4
  ;
}

.break-line-clamp {
  @apply line-clamp-3 break-words ;
}




